import {Badge, Box, Flex, IconButton, Switch, Text, Tooltip, useColorModeValue, useToast} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import {configs} from "../../Configs";
import {FaAt, FaRegStar, FaStar} from "react-icons/fa";
import useAxios from "../../hooks/useAxois";
import {useState} from "react";
import {useIntl} from 'react-intl';

export const CredentialCard = ({credential, refr}) => {
    const intl = useIntl();
    const toast = useToast();
    const axiosInstance = useAxios();
    const {hasPermission} = useAuth();
    const navigate = useNavigate();

    const [isStarred, setIsStarred] = useState(credential.has_star?.includes('CREDENTIAL'));

    const faIconStyle = {width: '13px', display: 'inline-block', marginBottom: '-2px'};
    const at = `${configs.credential_platforms.find(rp => rp.value === credential.platform)?.label}`;

    const toggleStar = async () => {
        try {
            await axiosInstance.patch(`/credential/${credential.credential_id}`);
            if (!isStarred) {
                setIsStarred(true);
                toast({
                    title: intl.formatMessage({id: 'credentials.marked'}),
                    status: "success",
                    isClosable: true,
                    diration: 1500,
                });
            } else {
                setIsStarred(false);

                toast({
                    title: intl.formatMessage({id: 'credentials.unmarked'}),
                    status: "success",
                    isClosable: true,
                    diration: 1500,
                });
            }
        } catch (err) {
            let msg = err?.response?.data?.detail || [];
            if (msg && !Array.isArray(msg)) {
                msg = [{msg: msg}]
            }
            if (!msg.length) {
                msg = [{
                    msg:
                        intl.formatMessage({id: 'responses.unknownError'})
                }]
            }
            msg.map(err =>
                toast({
                    title: err.msg,
                    status: "error",
                    isClosable: true,
                    diration: 1500,
                })
            );
        }
    }
    const goToCredential = (e) => {
        // takes no effect when clicked on elements with aria-label="no-action" and their children
        let element = e.target;
        while (element) {
            if (element.getAttribute('aria-label') === 'no-action') {
                return;
            }
            element = element.parentElement;
        }

        // otherwise redirect to soecified credential
        return hasPermission('credential', 'fe_edit') || hasPermission('credential', 'fe_delete') ? navigate(`/credentials/${credential.credential_id}`, {replace: true}) : null;
    }

    return (<Box
        ref={refr}
        bg={useColorModeValue("gray.200", "gray.300")}
        minHeight="3rem"
        my={3}
        p={3}
        rounded="lg"
        _hover={{
            opacity: 0.9, cursor: "pointer", transform: "translateY(-3px)",
        }}
        onClick={goToCredential}
    >
        <Flex
            width={'100%'}
            alignItems="center"
            justifyContent="space-between"
            mb={2}
        >
            <Text style={{overflow: 'hidden'}}>{credential.username}</Text>
            <Badge colorScheme={credential.status ? "green" : "purple"}>
                {credential.status ? intl.formatMessage({id: 'credentials.enabled'}) : intl.formatMessage({id: 'credentials.disabled'})}
            </Badge>
        </Flex>
        <Flex
            width={'100%'}
            alignItems="center"
            justifyContent="space-between"
        >
            <Text style={{overflow: 'hidden'}}>
                <Tooltip label={
                    intl.formatMessage({id: 'credentials.credentialPlatform'})
                }>
                    <span><FaAt style={faIconStyle}/>{at}</span>
                </Tooltip>
            </Text>
            <span aria-label={'no-action'}>
                {hasPermission('credential', 'fe_star') && <Tooltip
                    label={isStarred ? intl.formatMessage({id: 'credentials.unmark'}) : intl.formatMessage({id: 'credentials.mark'})}>
                    <IconButton
                        me={1}
                        size="sm"
                        icon={
                            isStarred ? <FaStar aria-label={'no-action'}
                                                style={{
                                                    color: 'orange',
                                                    display: 'inline-block',
                                                    marginBottom: '-1px'
                                                }}/> :
                                <FaRegStar aria-label={'no-action'}
                                           style={{color: 'orange', display: 'inline-block', marginBottom: '-1px'}}/>
                        }
                        onClick={toggleStar}
                    />
                </Tooltip>}
            </span>
        </Flex>
    </Box>);
};
