import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Stack,
    Switch,
    Textarea,
    useColorModeValue,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import {ViewIcon, ViewOffIcon} from '@chakra-ui/icons'
import {Controller, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {useContext, useState} from 'react';
import {configs} from '../../Configs';
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';
import {LocaleContext} from '../Theme/LocaleProvider';

export const AddUpdateCredentialModal = ({
                                             editable = false,
                                             defaultValues = {},
                                             onSuccess = () => {
                                             },
                                             ...rest
                                         }) => {
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const axiosInstance = useAxios();

    const {isOpen, onOpen, onClose} = useDisclosure();

    const toast = useToast();
    const {credentialId} = useParams();

    // set default platform if not already done
    if (!('platform' in defaultValues)) {
        defaultValues.platform = 'TWITTER';
    }

    const {
        handleSubmit,
        register,
        watch,
        control,
        formState: {errors, isSubmitting}
    } = useForm({defaultValues: {...defaultValues}});


    const onSubmit = async (values) => {
        try {
            if (editable) {
                await axiosInstance.put(`/credential/${credentialId}`, values);
            } else {
                await axiosInstance.post(`/credential/create/`, values);
            }

            toast({
                title: editable ? intl.formatMessage({id: 'credentials.updated'}) : intl.formatMessage({id: 'credentials.created'}),
                status: "success",
                isClosable: true,
                diration: 1500,
            });
            onSuccess();
            onClose();
        } catch (err) {
            let msg = err?.response?.data?.detail || [];
            if (msg && !Array.isArray(msg)) {
                msg = [{msg: msg}]
            }
            if (!msg.length) {
                msg = [{
                    msg:
                        intl.formatMessage({id: 'responses.unknownError'})
                }]
            }
            msg.map(err =>
                toast({
                    title: err.msg,
                    status: "error",
                    isClosable: true,
                    diration: 1500,
                })
            );
        }
    }

    const bgColor = useColorModeValue("gray.300", "gray.600");

    const [showPsw, setShowPsw] = useState(false);
    const [showOauthToken, setShowOauthToken] = useState(false);
    const [showOauthSecret, setShowOauthSecret] = useState(false);
    const [showCt0, setShowCt0] = useState(false);
    const [showBearer, setShowBearer] = useState(false);

    return (<Box {...rest}>
        <Button w="100%" colorScheme="green" onClick={onOpen}>
            {editable ? intl.formatMessage({id: 'credentials.updateCredential'}) : intl.formatMessage({id: 'credentials.addCredential'})}
        </Button>
        <Modal
            closeOnOverlayClick={false}
            size={{base: "full", md: "xl"}}
            onClose={onClose}
            isOpen={isOpen}
            // isCentered
        >
            <ModalOverlay/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                    <ModalHeader dir={context.isRTL ? 'rtl' : 'ltr'}>
                        <Heading fontSize='2xl' textAlign={'start'}>
                            {editable ? intl.formatMessage({id: 'credentials.updateCredential'}) : intl.formatMessage({id: 'credentials.addCredential'})}
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <span dir={context.isRTL ? 'rtl' : 'ltr'}>
                            <FormControl isInvalid={errors.platform}>
                            <Controller
                                rules={() => ({
                                    required: intl.formatMessage(
                                        {id: "validation.fieldRequired"},
                                        {field: intl.formatMessage({id: "credentials.platform"})}
                                    )
                                })}
                                name="platform"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return <Select
                                        variant="filled"
                                        size="lg"
                                        mt={6}
                                        value={value || ''}
                                        onChange={onChange}
                                    >
                                        {configs.credential_platforms.map((platform) => (
                                            <option key={"platform:" + platform.value}
                                                    value={platform.value}>{platform.label}</option>
                                        ))}
                                    </Select>
                                }}
                            />
                            <FormErrorMessage>
                                {errors.platform && errors.platform.message}
                            </FormErrorMessage>
                            </FormControl>
                        </span>

                        <FormControl isInvalid={errors.username}>
                            <Input
                                placeholder={intl.formatMessage({id: 'credentials.username'})}
                                background={bgColor}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}
                                {...register("username", {
                                    required: intl.formatMessage(
                                        {id: "validation.fieldRequired"},
                                        {field: intl.formatMessage({id: "credentials.username"})}
                                    ),
                                    minLength: {
                                        value: 4,
                                        message: intl.formatMessage(
                                            {id: "validation.fieldMinLen"},
                                            {
                                                field: intl.formatMessage({id: "credentials.username"}),
                                                length: 4
                                            }
                                        )
                                    },
                                    maxLength: {
                                        value: 64,
                                        message: intl.formatMessage(
                                            {id: "validation.fieldMaxLen"},
                                            {
                                                field: intl.formatMessage({id: "credentials.username"}),
                                                length: 64
                                            }
                                        )
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.username && errors.username.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.password}>
                            <InputGroup size='md' dir={'ltr'}>
                                <Input
                                    placeholder={intl.formatMessage({id: 'credentials.password'})}
                                    background={bgColor}
                                    type={showPsw ? 'text' : 'password'}
                                    variant="filled"
                                    size="lg"
                                    mt={6}
                                    {...register("password", {
                                        required: intl.formatMessage(
                                            {id: "validation.fieldRequired"},
                                            {field: intl.formatMessage({id: "credentials.password"})}
                                        ),
                                        minLength: {
                                            value: 4,
                                            message: intl.formatMessage(
                                                {id: "validation.fieldMinLen"},
                                                {
                                                    field: intl.formatMessage({id: "credentials.password"}),
                                                    length: 4
                                                }
                                            )
                                        },
                                        maxLength: {
                                            value: 128,
                                            message: intl.formatMessage(
                                                {id: "validation.fieldMaxLen"},
                                                {
                                                    field: intl.formatMessage({id: "credentials.password"}),
                                                    length: 128
                                                }
                                            )
                                        }
                                    })}
                                />
                                <InputRightElement width='4rem'>
                                    <IconButton h='1.75rem' size={'md'} icon={showPsw ? <ViewIcon/> : <ViewOffIcon/>}
                                                onClick={() => setShowPsw(!showPsw)}/>
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.password && errors.password.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.oauth_token}>
                            <InputGroup size='md'>
                                <Input
                                    placeholder={intl.formatMessage({id: 'credentials.oauthToken'})}
                                    background={bgColor}
                                    type={showOauthToken ? 'text' : 'password'}
                                    variant="filled"
                                    size="lg"
                                    mt={6}
                                    {...register("oauth_token", {
                                        required: intl.formatMessage(
                                            {id: "validation.fieldRequired"},
                                            {field: intl.formatMessage({id: "credentials.oauthToken"})}
                                        ),
                                        minLength: {
                                            value: 32,
                                            message: intl.formatMessage(
                                                {id: "validation.fieldMinLen"},
                                                {
                                                    field: intl.formatMessage({id: "credentials.oauthToken"}),
                                                    length: 32
                                                }
                                            )
                                        },
                                        maxLength: {
                                            value: 128,
                                            message: intl.formatMessage(
                                                {id: "validation.fieldMaxLen"},
                                                {
                                                    field: intl.formatMessage({id: "credentials.oauthToken"}),
                                                    length: 128
                                                }
                                            )
                                        }
                                    })}
                                />
                                <InputRightElement width='4rem'>
                                    <IconButton h='1.75rem' size='md'
                                                icon={showOauthToken ? <ViewIcon/> : <ViewOffIcon/>}
                                                onClick={() => setShowOauthToken(!showOauthToken)}/>
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.oauth_token && errors.oauth_token.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.oauth_secret}>
                            <InputGroup size='md'>
                                <Input
                                    placeholder={intl.formatMessage({id: 'credentials.oauthSecret'})}
                                    background={bgColor}
                                    type={showOauthSecret ? 'text' : 'password'}
                                    variant="filled"
                                    size="lg"
                                    mt={6}
                                    {...register("oauth_secret", {
                                        required: intl.formatMessage(
                                            {id: "validation.fieldRequired"},
                                            {field: intl.formatMessage({id: "credentials.oauthSecret"})}
                                        ),
                                        minLength: {
                                            value: 32,
                                            message: intl.formatMessage(
                                                {id: "validation.fieldMinLen"},
                                                {
                                                    field: intl.formatMessage({id: "credentials.oauthSecret"}),
                                                    length: 32
                                                }
                                            )
                                        },
                                        maxLength: {
                                            value: 128,
                                            message: intl.formatMessage(
                                                {id: "validation.fieldMaxLen"},
                                                {
                                                    field: intl.formatMessage({id: "credentials.oauthSecret"}),
                                                    length: 128
                                                }
                                            )
                                        }
                                    })}
                                />
                                <InputRightElement width='4rem'>
                                    <IconButton h='1.75rem' size='md'
                                                icon={showOauthSecret ? <ViewIcon/> : <ViewOffIcon/>}
                                                onClick={() => setShowOauthSecret(!showOauthSecret)}/>
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.oauth_secret && errors.oauth_secret.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.bearer}>
                            <InputGroup size='md'>
                                <Input
                                    placeholder={intl.formatMessage({id: 'credentials.bearer'})}
                                    background={bgColor}
                                    type={showBearer ? 'text' : 'password'}
                                    variant="filled"
                                    size="lg"
                                    mt={6}
                                    {...register("bearer", {
                                        required: intl.formatMessage(
                                            {id: "validation.fieldRequired"},
                                            {field: intl.formatMessage({id: "credentials.bearer"})}
                                        ),
                                        minLength: {
                                            value: 32,
                                            message: intl.formatMessage(
                                                {id: "validation.fieldMinLen"},
                                                {
                                                    field: intl.formatMessage({id: "credentials.bearer"}),
                                                    length: 32
                                                }
                                            )
                                        },
                                        maxLength: {
                                            value: 1000,
                                            message: intl.formatMessage(
                                                {id: "validation.fieldMaxLen"},
                                                {
                                                    field: intl.formatMessage({id: "credentials.bearer"}),
                                                    length: 1000
                                                }
                                            )
                                        }
                                    })}
                                />
                                <InputRightElement width='4rem'>
                                    <IconButton h='1.75rem' size='md'
                                                icon={showBearer ? <ViewIcon/> : <ViewOffIcon/>}
                                                onClick={() => setShowBearer(!showBearer)}/>
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.bearer && errors.bearer.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.cookies}>
                            <InputGroup size='md'>
                                <Input
                                    placeholder={intl.formatMessage({id: 'credentials.cookies'})}
                                    background={bgColor}
                                    type={showCt0 ? 'text' : 'password'}
                                    variant="filled"
                                    size="lg"
                                    mt={6}
                                    {...register("cookies", {
                                        required: intl.formatMessage(
                                            {id: "validation.fieldRequired"},
                                            {field: intl.formatMessage({id: "credentials.cookies"})}
                                        ),
                                        minLength: {
                                            value: 32,
                                            message: intl.formatMessage(
                                                {id: "validation.fieldMinLen"},
                                                {
                                                    field: intl.formatMessage({id: "credentials.cookies"}),
                                                    length: 32
                                                }
                                            )
                                        },
                                        maxLength: {
                                            value: 1000,
                                            message: intl.formatMessage(
                                                {id: "validation.fieldMaxLen"},
                                                {
                                                    field: intl.formatMessage({id: "credentials.cookies"}),
                                                    length: 1000
                                                }
                                            )
                                        }
                                    })}
                                />
                                <InputRightElement width='4rem'>
                                    <IconButton h='1.75rem' size='md'
                                                icon={showCt0 ? <ViewIcon/> : <ViewOffIcon/>}
                                                onClick={() => setShowCt0(!showCt0)}/>
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.cookies && errors.cookies.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.description}>
                            <Textarea
                                rows={5}
                                placeholder={intl.formatMessage({id: 'credentials.description'})}
                                background={bgColor}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}
                                {...register("description", {
                                    maxLength: {
                                        value: 200,
                                        message: intl.formatMessage(
                                            {id: "validation.fieldMaxLen"},
                                            {
                                                field: intl.formatMessage({id: "credentials.description"}),
                                                length: 200
                                            }
                                        )
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.description && errors.description.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.session}>
                            <Textarea
                                rows={5}
                                placeholder={intl.formatMessage({id: 'credentials.session'})}
                                background={bgColor}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}
                                {...register("session", {
                                    maxLength: {
                                        value: 2000000,
                                        message: intl.formatMessage(
                                            {id: "validation.fieldMaxLen"},
                                            {
                                                field: intl.formatMessage({id: "credentials.session"}),
                                                length: 2000000
                                            }
                                        )
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.session && errors.session.message}
                            </FormErrorMessage>
                        </FormControl>

                        <span dir={context.isRTL ? 'rtl' : 'ltr'}>
                            <Controller
                                control={control}
                                name="status"
                                render={({field}) => (<FormControl mt={6} display="flex" alignItems="center">
                                    <FormLabel htmlFor="is-done">
                                        <FormattedMessage id="credentials.status"/>
                                    </FormLabel>
                                    <Switch
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        isChecked={field.value}
                                        id="status"
                                        size="lg"
                                        name="status"
                                        isDisabled={false}
                                        colorScheme="green"
                                        variant="ghost"
                                    />
                                </FormControl>)}
                            />
                        </span>
                    </ModalBody>
                    <ModalFooter>
                        <Stack direction="row" spacing={4}>
                            <Button onClick={onClose} disabled={isSubmitting}>
                                <FormattedMessage id="credentials.close"/>
                            </Button>
                            <Button
                                colorScheme="green"
                                type="submit"
                                isLoading={isSubmitting}
                                loadingText={editable ? intl.formatMessage({id: 'credentials.updating'}) : intl.formatMessage({id: 'credentials.creating'})}
                            >
                                {editable ? intl.formatMessage({id: 'credentials.update'}) : intl.formatMessage({id: 'credentials.create'})}
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    </Box>);
};
