import {Box, Container, Tooltip} from "@chakra-ui/react";
import {configs} from "../../Configs";
import {FormattedMessage} from 'react-intl';
import {ResourcesStatisticsTable} from "../Charts/ResourcesStatisticsTable";
import {PostsPlatformAreaChart} from "../Charts/PostsPlatformAreaChart";
import {PostsByCategoriesSunburstChart} from "../Charts/PostsByCategoriesSunburstChart";
import {PostsByTopicSunburstChart} from "../Charts/PostsByTopicSunburstChart";
import {PostsByPlatformsMultibarChart} from "../Charts/PostsByPlatformsMultibarChart";
import {PostsProjectDonutChart} from "../Charts/PostsProjectDonutChart";
import {MostActiveResourceBarChart} from "../Charts/MostActiveResourceBarChart";
import {PostsByStatesMapChart} from "../Charts/PostsByStatesMapChart";
import {PostsPlatformPieChart} from "../Charts/PostsPlatformPieChart";
import {ResourcesByStatesMapChart} from "../Charts/ResourcesByStatesMapChart";
import {PostsMonthWaketimeChart} from "../Charts/PostsMonthWaketimeChart";
import {PostsWeekdayWaketimeChart} from "../Charts/PostsWeekdayWaketimeChart";
import Masonry from 'react-masonry-css';
import './Masonry.css';
import PopIcon from "./PopIcon";
import {FaTelegram} from "react-icons/fa";
import {PostsTopWordsChart} from "../Charts/PostsTopWordsChart";
import {MostUsedWordsTable} from "../Charts/MostUsedWordsTable";
import {useEffect, useState} from "react";
import useAxios from "../../hooks/useAxois";
import {NavBar} from "./NavBar";
import {useAuth} from "../../hooks/useAuth";

export const Dashboard = () => {
    const {hasPermission} = useAuth();
    const hasPermit = hasPermission('dashboard', 'fe_filters_view');
    const axiosInstance = useAxios();
    const [resources, setResources] = useState([]);
    const [selFactions, setSelFactions] = useState([]);
    const [selPostCats, setSelPostCats] = useState([]);
    const [since, setSince] = useState();
    const [until, setUntil] = useState();

    // pagination related stuff
    const [allPostTags, setAllPostTags] = useState([]);
    const [postTagsLoading, setPostTagsLoading] = useState(true);
    const [factions, setFactions] = useState([]);
    const [factionsLoading, setFactionsLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        axiosInstance.get("/post_tag").then((data) => {
            setAllPostTags(data.data);
            setPostTagsLoading(false);
            setError(false);
        }).catch((error) => {
            setPostTagsLoading(false);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
        axiosInstance.get("/raqib2_tag").then((data) => {
            setFactions(data.data);
            setFactionsLoading(false);
            setError(false);
        }).catch((error) => {
            setFactionsLoading(false);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
    }, []);

    const parseFilters = () => {
        if (postTagsLoading || factionsLoading) {
            return {parentIsLoading: true}
        }
        const payload = {
            // headers: {'Client-Timezone': configs.time_locale},
            parentIsLoading: false
        };

        if (since) {
            payload.since = since.toISOString();
        }

        if (until) {
            payload.until = until.toISOString();
        }

        // change since/until if they are entered in reverse
        if (since && until && until < since) {
            payload.since = until.toISOString();
            payload.until = since.toISOString();
        }

        const tag_ids = selPostCats.map(tag => tag.value);
        if (tag_ids) {
            payload.tag_ids = tag_ids;
        }

        const faction_ids = selFactions.map(tag => tag.value);
        if (faction_ids) {
            payload.faction_ids = faction_ids;
        }

        const resource_ids = resources.map(resource => resource.value);
        if (resource_ids) {
            payload.resource_ids = resource_ids;
        }

        return payload;
    }
    const filters = parseFilters();
    return (<Container mt={9} maxW={'8xl'} pb={20} style={{position: 'relative'}}>
        <Box my={8}>
            <h1 style={{fontSize: '30px', fontWeight: 800, textAlign: "center"}}>
                <FormattedMessage id="dashboard.title"/>
            </h1>
        </Box>

        {!!hasPermit && <NavBar
            factions={factions}
            postCats={allPostTags}
            resources={resources}
            setResources={setResources}
            since={since}
            setSince={setSince}
            until={until}
            setUntil={setUntil}
            setSelFactions={setSelFactions}
            selFactions={selFactions}
            setSelPostCats={setSelPostCats}
            selPostCats={selPostCats}
        />}
        <PostsByPlatformsMultibarChart mb={2} {...filters}/>
        <PostsPlatformAreaChart mb={2} {...filters}/>
        <PostsWeekdayWaketimeChart mb={2} {...filters}/>
        <PostsMonthWaketimeChart mb={2} {...filters}/>
        <MostActiveResourceBarChart mb={2} {...filters}/>
        <ResourcesStatisticsTable mb={2} {...filters} minH={"37rem"} maxH={"46rem"}/>
        <Masonry
            breakpointCols={{
                default: 2, 1100: 3, 700: 1
            }}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
        >
            <PostsTopWordsChart minH={"37rem"} maxH={"37rem"} {...filters}/>
            <MostUsedWordsTable minH={"37rem"} maxH={"37rem"} {...filters}/>
            <PostsByCategoriesSunburstChart minH={"37rem"} maxH={"37rem"} {...filters}/>
            <PostsByTopicSunburstChart minH={"37rem"} maxH={"37rem"} {...filters}/>
            <PostsProjectDonutChart minH={"37rem"} maxH={"37rem"} {...filters}/>
            <PostsByStatesMapChart minH={"37rem"} maxH={"37rem"} {...filters}/>
            <PostsPlatformPieChart minH={"37rem"} maxH={"37rem"} {...filters}/>
            <ResourcesByStatesMapChart minH={"37rem"} maxH={"37rem"} {...filters}/>
        </Masonry>

        <PopIcon icon={<Tooltip placement='top-start' label={<FormattedMessage id="dashboard.supportButton"/>}>
                    <span>
                        <FaTelegram
                            cursor={'pointer'}
                            fontSize={50}
                            color="#0088CC"
                            onClick={() => (window.open(configs.raqib_support_bot_url, '_blank')).focus()}
                        />
                    </span>
        </Tooltip>}/>
    </Container>);
};
