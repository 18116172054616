import {Box, Link, Td, Text, Tr} from '@chakra-ui/react';
import {FormattedMessage} from "react-intl";

export const ResourcesStatisticsRow = ({
                                           row_id,
                                           title,
                                           count,
                                           url,
                                           platform,
                                           stripped = false,
                                           ...rest
                                       }) => {
    return (
        <Tr fontSize={'sm'} bgColor={stripped ? '#f2f2f2' : 'white'} {...rest}>
            <Td p={3}>
                <Box maxW="sm" isTruncated>{row_id}</Box>
            </Td>
            <Td p={3}>
                {!!url && <Link href={url} isExternal>[{title}]</Link>}
                {!url && title}
            </Td>
            <Td p={3}>
                <Text>{count}</Text>
            </Td>
            <Td p={3}>
                <Text><FormattedMessage id={`charts.resources.statisticsTable.${platform}`}/></Text>
            </Td>
        </Tr>
    );
};