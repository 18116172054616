import {Button, Center, Container, Spinner, Text, useColorModeValue, useToast,} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AddUpdateCredentialModal} from "./AddUpdateCredentialModal";
import {useAuth} from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';
// import {CredentialActivityAreaChart} from "../Charts/CredentialActivityAreaChart";

export const CredentialDetail = () => {
    const intl = useIntl();
    const axiosInstance = useAxios();
    const {hasPermission} = useAuth();
    const [credential, setCredential] = useState({});
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(false);
    const {credentialId} = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const background = useColorModeValue("gray.300", "gray.600");

    useEffect(() => {
        if (isMounted.current) return;
        fetchCredential();
        isMounted.current = true;
    }, [credentialId]);

    const fetchCredential = () => {
        setLoading(true);
        axiosInstance
            .get(`/credential/${credentialId}`)
            .then((res) => {
                setCredential(res.data);
            })
            .catch((error) => console.error(error))
            .finally(() => {
                setLoading(false);
            });
    };

    const delateCredential = () => {
        setLoading(true);
        axiosInstance
            .delete(`/credential/${credentialId}`)
            .then(() => {
                toast({
                    title: intl.formatMessage({id: 'credentials.deleted'}),
                    status: "success",
                    isClosable: true,
                    diration: 1500,
                });
                navigate("/credentials");
            })
            .catch((err) => {
                console.error(err);
                toast({
                    title: intl.formatMessage({id: 'credentials.notDeleted'}),
                    status: "error",
                    isClosable: true,
                    diration: 2000,
                });
            })
            .finally(() => setLoading(false));
    };

    if (loading) {
        return (
            <Container mt={6}>
                <Center mt={6}>
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="green.200"
                        color="green.500"
                        size="xl"
                    />
                </Center>
            </Container>
        );
    }

    return (
        <>
            <Container mt={6}>
                <Button
                    colorScheme="gray"
                    onClick={() => navigate("/credentials", {replace: true})}
                >
                    <FormattedMessage id="navigations.back"/>
                </Button>
            </Container>
            <Container
                bg={background}
                minHeight="7rem"
                mt={3}
                p={3}
                rounded="lg"
                alignItems="center"
                justifyContent="space-between"
            >
                {/*<CredentialActivityAreaChart credentialId={credentialId}/>*/}
                <Text fontSize={22}>{credential.username}</Text>
                {credential.description && <Text bg="gray.400" mt={2} p={2} rounded="lg">
                    {credential.description}
                </Text>}
                {hasPermission('credential', 'fe_edit') && <AddUpdateCredentialModal
                    mt={3}
                    editable={true}
                    defaultValues={{
                        username: credential.username,
                        password: credential.password,
                        oauth_token: credential.oauth_token,
                        oauth_secret: credential.oauth_secret,
                        bearer: credential.bearer,
                        cookies: credential.cookies,
                        session: credential.session,
                        description: credential.description,
                        platform: credential.platform,
                        updated_at: credential.updated_at,
                        created_at: credential.created_at,
                        status: credential.status,
                    }}
                    onSuccess={fetchCredential}
                />}

                {hasPermission('credential', 'fe_delete') && <Button
                    mt={3}
                    isLoading={loading}
                    colorScheme="red"
                    width="100%"
                    onClick={delateCredential}
                >
                    <FormattedMessage id="credentials.delete"/>
                </Button>}
            </Container>
        </>
    );
};
