import {Box, Spinner, Table, Tbody, Text, Tfoot, Th, Thead, Tr} from "@chakra-ui/react";
import {useContext, useEffect, useState} from 'react';
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {useAuth} from "../../hooks/useAuth";
import {generateRandomKey, parseDashboardChartsFilters} from "../../utils/helpers";
import {ResourcesStatisticsRow} from "./ResourcesStatisticsRow";
import {configs} from "../../Configs";
import {FormattedMessage} from 'react-intl';


export const ResourcesStatisticsTable = ({
                                             parentIsLoading,
                                             since,
                                             until,
                                             resource_ids,
                                             tag_ids,
                                             faction_ids,
                                             ...rest
                                         }) => {
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const {hasPermission, user} = useAuth();
    const hasPermit = hasPermission('charts:resources:statistics-table', 'fe_statistics_table');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit);
    const [totalPostCnt, setTotalPostCnt] = useState(0);
    const [totalResourceCnt, setTotalResourceCnt] = useState(0);
    const [error, setError] = useState(false);

    const loadData = () => {
        if (!hasPermit || parentIsLoading) return;
        setIsLoading(hasPermit);

        let payload = {
            since: since,
            until: until,
        }

        const url = parseDashboardChartsFilters("/charts/resources/statistics-table?", tag_ids, faction_ids, resource_ids);
        axiosInstance.get(url, {headers: {'Client-Timezone': configs.time_locale}, params: payload}).then((res) => {
            const items = [].concat(...res?.data.rows);

            setTotalPostCnt(res?.data.total_post_count)
            setTotalResourceCnt(res?.data.total_resource_count)
            setData((oldData) => {
                const parsedData = [...oldData, ...items.map(item => ({...item, platform: item.platform}))]
                const uniqueData = Array.from(new Map(parsedData.map(item => [item.resource_id, item])).values());
                uniqueData.sort((a, b) => b.count - a.count);
                return uniqueData
            });
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }
    // clear previous filtered data
    useEffect(() => {
        setData([]);
        loadData();
    }, [since, until, resource_ids, tag_ids, faction_ids]);

    return (
        <Box
            overflowX="auto"
            bg={"gray.100"}
            minHeight="3rem"
            my={2}
            p={3}
            py={5}
            rounded="lg"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            {...rest}
        >
            <Text mb={5} fontSize={25} fontWeight={500} dir={context.isRTL ? 'rtl' : 'ltr'}>
                <FormattedMessage id="charts.resources.statisticsTable"/>
            </Text>

            {(!!isLoading || !!parentIsLoading) && !data.length && <Spinner color="green"/>}

            {!data.length && !isLoading && !parentIsLoading && <Text><FormattedMessage id="responses.noData"/></Text>}

            {!data.length && !isLoading && !parentIsLoading && error &&
                <Text><FormattedMessage id="responses.connectionFailed"/></Text>}

            {data.length > 0 && <Table>
                <Thead>
                    <Tr>
                        <Th p={3}><FormattedMessage id="charts.resources.statisticsTable.id"/></Th>
                        <Th p={3} minW="100px"><FormattedMessage
                            id="charts.resources.statisticsTable.title"/></Th>
                        <Th p={3} minW="100px"><FormattedMessage id="charts.resources.statisticsTable.count"/></Th>
                        <Th p={3} minW="100px"><FormattedMessage id="charts.resources.statisticsTable.platform"/></Th>
                    </Tr>
                </Thead>
                <Tbody style={{fontFamily: "IRANSans, sans-serif"}}>
                    {data.map((record, idx) => {
                        return <ResourcesStatisticsRow
                            key={generateRandomKey()}
                            row_id={idx + 1}
                            stripped={idx % 2 === 0}
                            {...record}/>
                    })}
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Th>
                            <FormattedMessage id="charts.resources.statisticsTable.totalResources"/> : {totalResourceCnt}
                        </Th>
                        <Th>
                            <FormattedMessage id="charts.resources.statisticsTable.topResources"/> : {data.length}
                        </Th>
                        <Th>
                            <FormattedMessage id="charts.resources.statisticsTable.totalPosts"/> : {totalPostCnt}
                        </Th>
                        <Th>
                            <FormattedMessage id="charts.resources.statisticsTable.sumPosts"/> : {data.reduce((sum, item) => sum + item.count, 0)}
                        </Th>
                    </Tr>
                </Tfoot>
            </Table>}
        </Box>);
};
