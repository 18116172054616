import {
    Box,
    Button,
    ButtonGroup,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Textarea,
    Tooltip,
    useColorModeValue,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import {CloseIcon} from '@chakra-ui/icons';
import {Controller, useForm} from "react-hook-form";
import {useContext, useEffect, useState} from 'react';
import {configs} from '../../Configs';
import ReactSelect from 'react-select';
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';
import {LocaleContext} from '../Theme/LocaleProvider';

export const SearchCredentialModal = ({
                                        filters,
                                        setFilters,
                                        ...rest
                                    }) => {
    const context = useContext(LocaleContext);
    const intl = useIntl();

    const {isOpen, onOpen, onClose} = useDisclosure();

    const toast = useToast();

    const defaultValues = {};

    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: {errors, isSubmitting}
    } = useForm({defaultValues: {...defaultValues}});

    const [isResetted, setIsResetted] = useState(false);

    if (!isResetted && Object.keys(filters || {})?.length === 0) {
        reset();
        setIsResetted(true);
    }
    useEffect(() => setIsResetted(false), [filters]);

    const onSubmit = (values) => {
        try {

            if (values.has_star !== 'false' && !values.has_star) {
                delete values.has_star;
            }

            if (values.status !== 'false' && !values.status) {
                delete values.status;
            }

            if (!values.description) {
                delete values.description;
            }

            setFilters(values);
            onClose();
        } catch (err) {
            let msg = err?.response?.data?.detail || [];
            if (msg && !Array.isArray(msg)) {
                msg = [{msg: msg}]
            }
            if (!msg.length) {
                msg = [{msg: intl.formatMessage({id: 'responses.unknownError'})}]
            }
            msg.map(err =>
                toast({
                    title: err.msg,
                    status: "error",
                    isClosable: true,
                    diration: 1500,
                })
            );
        }
    }

    const bgColor = useColorModeValue("gray.300", "gray.600");

    return (<Box {...rest}>
        <ButtonGroup size='sm' w={'100%'} isAttached variant="outline">
            <Tooltip label={intl.formatMessage({id: 'credentials.changeSearchFilters'})}>
                <Button colorScheme="blue" w={'100%'} onClick={onOpen}>
                    <FormattedMessage id="credentials.search"/>
                </Button>
            </Tooltip>
            {Object.keys(filters || {}).length > 0 && <Tooltip
                label={intl.formatMessage({id: 'credentials.removeAllFilters'})}>
                <IconButton colorScheme='red' icon={<CloseIcon/>} onClick={() => setFilters({})}/>
            </Tooltip>}
        </ButtonGroup>
        <Modal
            closeOnOverlayClick={false}
            size={{base: "full", md: "xl"}}
            onClose={onClose}
            isOpen={isOpen}
            // isCentered
        >
            <ModalOverlay/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                    <ModalHeader dir={context.isRTL ? 'rtl' : 'ltr'}>
                        <Heading fontSize='2xl' textAlign={'start'}>
                            <FormattedMessage id="credentials.searchCredential"/>
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <span dir={context.isRTL ? 'rtl' : 'ltr'}>
                            <FormControl isInvalid={errors.platform}>
                                <Controller
                                    name="platform"
                                    control={control}
                                    render={({field: {onChange, value}}) => {
                                        return <Select
                                            variant="filled"
                                            size="lg"
                                            mt={6}
                                            value={value || 'all'}
                                            onChange={onChange}
                                        >
                                            {[...configs.credential_platforms, {
                                                value: 'all',
                                                label: intl.formatMessage({id: 'credentials.all'})
                                            }].map((platform) => (
                                                <option key={"platform:" + platform.value}
                                                        value={platform.value}>{platform.label}</option>
                                            ))}
                                        </Select>
                                    }}
                                />
                                <FormErrorMessage>
                                    {errors.platform && errors.platform.message}
                                </FormErrorMessage>
                            </FormControl>
                        </span>

                        <FormControl isInvalid={errors.username}>
                            <Input
                                placeholder={intl.formatMessage({id: 'credentials.username'})}
                                background={bgColor}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}
                                {...register("username", {
                                    minLength: {
                                        value: 4,
                                        message: intl.formatMessage(
                                            {id: "validation.fieldMinLen"},
                                            {
                                                field: intl.formatMessage({id: "credentials.username"}),
                                                length: 4
                                            }
                                        )
                                    },
                                    maxLength: {
                                        value: 512,
                                        message: intl.formatMessage(
                                            {id: "validation.fieldMaxLen"},
                                            {
                                                field: intl.formatMessage({id: "credentials.username"}),
                                                length: 512
                                            }
                                        )
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.username && errors.username.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.description}>
                            <Textarea
                                rows={5}
                                placeholder={intl.formatMessage({id: 'credentials.description'})}
                                background={bgColor}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}
                                {...register("description", {
                                    maxLength: {
                                        value: 200,
                                        message: intl.formatMessage(
                                            {id: "validation.fieldMaxLen"},
                                            {
                                                field: intl.formatMessage({id: "credentials.description"}),
                                                length: 200
                                            }
                                        )
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.description && errors.description.message}
                            </FormErrorMessage>
                        </FormControl>

                        <span dir={context.isRTL ? 'rtl' : 'ltr'}>
                            <Controller
                                control={control}
                                name="has_star"
                                render={({field}) => (<FormControl mt={6} display="flex" alignItems="center">
                                    <FormLabel htmlFor="has-star">
                                        <FormattedMessage id="credentials.hasStar"/>
                                    </FormLabel>
                                    <RadioGroup id="has-star" value={field.value} onChange={(e) => field.onChange(e)}>
                                        <Stack direction='row'>
                                            <Radio name="has_star" value='' colorScheme="green">
                                                <FormattedMessage id="credentials.all"/>
                                            </Radio>
                                            <Radio name="has_star" value='true' colorScheme="green">
                                                <FormattedMessage id="credentials.yes"/>
                                            </Radio>
                                            <Radio name="has_star" value='false' colorScheme="green">
                                                <FormattedMessage id="credentials.no"/>
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>)}
                            />
                            <Controller
                                control={control}
                                name="status"
                                render={({field}) => (<FormControl mt={6} display="flex" alignItems="center">
                                    <FormLabel htmlFor="is-done">
                                        <FormattedMessage id="credentials.status"/>
                                    </FormLabel>
                                    <RadioGroup id="status" value={field.value} onChange={(e) => field.onChange(e)}>
                                        <Stack direction='row'>
                                            <Radio name="status" value='' colorScheme="green">
                                                <FormattedMessage id="credentials.all"/>
                                            </Radio>
                                            <Radio name="status" value='true' colorScheme="green">
                                                <FormattedMessage id="credentials.enabled"/>
                                            </Radio>
                                            <Radio name="status" value='false' colorScheme="green">
                                                <FormattedMessage id="credentials.disabled"/>
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>)}
                            />
                        </span>
                    </ModalBody>
                    <ModalFooter>
                        <Stack direction="row" spacing={4}>
                            <Button onClick={onClose} disabled={isSubmitting}>
                                <FormattedMessage id="credentials.close"/>
                            </Button>
                            <Button colorScheme={'red'} onClick={() => {
                                setFilters({});
                                reset();
                                return onClose();
                            }} disabled={isSubmitting}>
                                <FormattedMessage id="credentials.clear"/>
                            </Button>
                            <Button
                                colorScheme="blue"
                                type="submit"
                                isLoading={isSubmitting}
                                loadingText={intl.formatMessage({id: 'credentials.searching'})}
                            >
                                <FormattedMessage id="credentials.search"/>
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    </Box>);
};
