const now = new Date();
const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
const today = now.getTime() - midnight.getTime() > 0 ? ((now.getTime() - midnight.getTime()) / 1000).toFixed() : 0;

const configs = {
    country_code: 'ac',
    ui_font_family: 'BlinkMacSystemFont, Cairo, IRANSans, sans-serif',
    posts_heading_font_family: 'Cairo',
    posts_body_font_family: 'Cairo, sans-serif',
    base_back_url: 'http://ac-raqib.ai:2056/api/v1/',
    // base_back_url: 'http://127.0.0.1:8000/api/v1/',
    // raqib_support_bot_url: 'tg://resolve?domain=ac_raqib_support_bot',
    raqib_support_bot_url: 'https://web.telegram.org/k/#@ac_raqib_support_bot',
    // time_locale: 'utc',
    // time_locale: 'Asia/Tehran',
    time_locale: Intl.DateTimeFormat().resolvedOptions().timeZone,   //Detect browser timezone and show as that zone
    target_location: {
        country: 'Africa',
        google_trends_country_code: 'AC',
    },
    roles: [
        {label: 'Superadmin', value: 'super_admin'},
        {label: 'Admin', value: 'admin'},
        {label: 'East Admin', value: 'east_admin'},
        {label: 'West Admin', value: 'west_admin'},
        {label: 'Viewer', value: 'viewer'},
        {label: 'East Viewer', value: 'east_viewer'},
        {label: 'West Viewer', value: 'west_viewer'},
        {label: 'Disabled', value: 'disabled'}
    ],
    projects: [
        {label: 'Map', value: 'RAQIB1'},
        {label: 'Monitoring', value: 'RAQIB6'},
        {label: 'East News', value: 'RAQIB5'},
        {label: 'West News', value: 'RAQIB7'},
        {label: 'Topics', value: 'RAQIB2'},
        {label: 'Image Trends', value: 'RAQIB3'},
        {label: 'Contextual Trends', value: 'RAQIB4'},
    ],
    locations: [
        {
            label: "International",
            value: "international",
            geo: {lat: 43.081238098979675, lon: 64.78127957947278},
            zoom: 3.053770845386799
        },
        {
            label: "Algeria",
            value: "algeria",
            geo: {lat: 27.19086118824256, lon: 2.53766866470281},
            zoom: 4.475119456181579
        },
        {
            label: "Egypt",
            value: "egypt",
            geo: {lat: 26.410052448770426, lon: 29.365959177428724},
            zoom: 5.5211939364125655
        },
        {
            label: "Morocco",
            value: "morocco",
            geo: {lat: 28.119348197081464, lon: -10.559764957869906},
            zoom: 4.96708683344359
        },
        {
            label: "Tunisia",
            value: "tunisia",
            geo: {lat: 34.23774685952994, lon: 9.309253956372034},
            zoom: 6.456089080242071
        },
        {
            label: "Libya",
            value: "libya",
            geo: {lat: 26.946289860449717, lon: 17.40018166121288}, zoom: 5.4304117225654736
        },
        {
            label: "Mauritania",
            value: "mauritania",
            geo: {lat: 19.652425934431275, lon: -10.24534642103569}, zoom: 5.726002218587564
        },
        {
            label: "Comoros",
            value: "comoros",
            geo: {lat: -11.864360364014752, lon: 43.73029802057049}, zoom: 8.929661406507314
        },
        {
            label: "Djibouti",
            value: "djibouti",
            geo: {lat: 11.744279226034559, lon: 42.39095360963026}, zoom: 8.10687874002548
        },
        {
            label: "Ethiopia",
            value: "ethiopia",
            geo: {lat: 8.211831454126568, lon: 39.36237810171621},
            zoom: 6.212024696639429
        },
        {
            label: "Madagascar",
            value: "madagascar",
            geo: {lat: -19.44580782005152, lon: 46.97997597496943}, zoom: 5.610987645575474
        },
        {
            label: "Somalia",
            value: "somalia",
            geo: {lat: 5.286253928900556, lon: 46.648176242844116}, zoom: 5.589828376077554
        },
        {
            label: "Sudan",
            value: "sudan",
            geo: {lat: 16.775980736915418, lon: 29.89134905053501}, zoom: 5.449522523337542
        },
        {
            label: "South Africa",
            value: "south africa",
            geo: {lat: -29.55370478010828, lon: 24.801993270533224}, zoom: 5.38345491612786
        },
        {
            label: "Eritrea",
            value: "eritrea",
            geo: {lat: 15.19766865667153, lon: 39.60754578165423}, zoom: 6.868019490865042
        },
        {
            label: "Kenya",
            value: "kenya",
            geo: {lat: 0.4126642870452248, lon: 37.66306680870685}, zoom: 6.144510697705137
        },
        {
            label: "Uganda",
            value: "uganda",
            geo: {lat: 1.315857004815219, lon: 32.409316288717385}, zoom: 6.70789767736508
        },
        {
            label: "Tanzania",
            value: "tanzania",
            geo: {lat: -6.789789529455211, lon: 35.43488802917492}, zoom: 6.229035604005521
        },
        {
            label: "Rwanda",
            value: "rwanda",
            geo: {lat: -2.0010905914761894, lon: 29.925935453517127}, zoom: 8.567938251143527
        },
        {
            label: "Congo",
            value: "congo",
            geo: {lat: -1.3717662498922523, lon: 15.335214084918896}, zoom: 6.424664094966651
        },
        {
            label: "Ivory Coast",
            value: "ivory coast",
            geo: {lat: 7.060025094808458, lon: -5.638545816862981}, zoom: 6.549406019873275
        },
        {
            label: "Nigeria",
            value: "nigeria",
            geo: {lat: 9.03798681851717, lon: 7.995405502766141}, zoom: 5.41049795414044
        },
        {
            label: "Niger",
            value: "niger",
            geo: {lat: 16.92482405324239, lon: 9.40788321851767}, zoom: 5.713646067922345
        },
        {
            label: "Cameroon",
            value: "cameroon",
            geo: {lat: 5.008841308013125, lon: 12.353284519101106}, zoom: 6.406466729266609
        },
        {
            label: "Senegal",
            value: "senegal",
            geo: {lat: 14.264521620722947, lon: -14.842282464076344}, zoom: 6.813371212287422
        },
        {
            label: "Mali",
            value: "mali",
            geo: {lat: 17.886862198242362, lon: -3.771695815119098}, zoom: 5.531414980112597
        },
        {
            label: "Ghana",
            value: "ghana",
            geo: {lat: 8.531264276011793, lon: -1.2230385786258484}, zoom: 6.83043416912476
        },
        {
            label: "Burkina Faso",
            value: "burkina faso",
            geo: {lat: 12.382858368278383, lon: -1.8945236801036114}, zoom: 6.9279816059324775
        }
    ],
    exports: {
        templates: [
            {label: "Simple", value: "SIMPLE"},
            {label: "Observatory", value: "OBSERVATORY"}
        ],
        fields: [
            {label: "Title", value: 'title'},
            {label: "Translate link", value: 'translate_link'},
            {label: "Link", value: 'link'},
            {label: "Hashtags", value: 'hashtags'},
            {label: "Mentions", value: 'mentions'},
            {label: "Pub date", value: 'pubDate'},
            {label: "Has star", value: 'raqib_metadata.has_star'},
            {label: "Origin url", value: 'raqib_metadata.target.origin_url'},
            {label: "Target", value: 'raqib_metadata.target.title'},
            {label: "Platform", value: 'raqib_metadata.target.rss_platform'},
            {label: "Tags", value: 'raqib_metadata.tags.title'},
            {label: "Fetched at", value: 'raqib_metadata.fetched_at'}
        ]
    },
    raqib1: {
        mapsOsmProvider: {
            token: "pk.eyJ1IjoibWZyYXNvdWxuZWphZCIsImEiOiJjbGM0cTR6aHkwcW8xM3ZwZ3hoYTNqNjY5In0.A7GD0TuHDQuzblYcGyh25g",
            attribution: {
                show: false,
                text: ""
            },
            colors: {
                'TRENDS24': [113, 128, 150],
                'TRENDSMAP': [113, 128, 150],
                'GOOGLE_TRENDS': [56, 161, 105],
                'TWITTER_TRENDS': [26, 32, 44],
                'TWITTER': [26, 32, 44],
                'RSS_INSTAGRAM': [229, 62, 62],
                'RSS_FACEBOOK': [43, 108, 176],
                'RSS_YOUTUBE': [128, 90, 213],
                'RSS_TIKTOK': [213, 63, 140],
                'RSS_TELEGRAM': [0, 136, 204],
                'TELEGRAM': [0, 136, 204],
                'RSS_OTHER': [214, 158, 46],
                'RSS_VK': [49, 151, 149],
                'RSS_OK': [0, 181, 216]
            },
            deckGl: {
                showCoordinates: false,
                controller: {
                    doubleClickZoom: false,
                    keyboard: false,
                    scrollZoom: false,
                    touchZoom: false,
                    dragPan: false
                },
                // lightStyle: 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json',
                lightStyle: 'mapbox://styles/mapbox/satellite-v9',
                // darkStyle: 'mapbox://styles/mapbox/dark-v9',
                darkStyle: 'mapbox://styles/mapbox/satellite-v9',
                terrain: {source: 'mapbox-dem', exaggeration: 1.5},
                scrollZoom: false,
                touchZoom: false,
                keyboard: false,
                doubleClickZoom: false,
                initial_states: {
                    longitude: 12.271525864034981,
                    latitude: 2.294905847805313,
                    zoom: 2.8828399501144113,
                    pitch: 0,
                    bearing: 0
                }
            }
        },
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: false,
        sources: [
            {label: 'Trends24', value: 'TRENDS24'},
            {label: 'Trendsmap', value: 'TRENDSMAP'},
            {label: 'Google', value: 'GOOGLE_TRENDS'},
            {label: 'X', value: 'TWITTER'}
        ],
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'}
        ],
        mapMinFontSize: 15,
        mapMaxFontSize: 30,
        mapsSourcesVals: {
            // TRENDSMAP: 'TRENDSMAP',
            TRENDS24: 'TRENDS24',
            GOOGLE_TRENDS: 'GOOGLE_TRENDS',
            TWITTER: 'TWITTER',
            RSS_FACEBOOK: 'RSS_FACEBOOK',
            RSS_INSTAGRAM: 'RSS_INSTAGRAM',
            RSS_TIKTOK: 'RSS_TIKTOK',
            RSS_YOUTUBE: 'RSS_YOUTUBE',
            TELEGRAM: 'TELEGRAM',
            RSS_OTHER: 'RSS_OTHER',
            RSS_VK: 'RSS_VK',
            RSS_OK: 'RSS_OK'
        },
        mapsFilters: {
            timeranges: [
                {label: 'Last Hour', value: 3600},
                {label: 'Last 24 Hours', value: 86400},
                {label: 'Last 7 Days', value: 604800},
                {label: 'Last 30 Days', value: 2592000}
            ],
        }
    },
    raqib2: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: false,
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib3: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: false,
        periods: [
            {label: '1 Hour', value: 3600},
            {label: '2 Hours', value: 7200},
            {label: '3 Hours', value: 10800},
            {label: '6 Hours', value: 21600},
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib4: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: false,
        periods: [
            {label: '1 Hour', value: 3600},
            {label: '2 Hours', value: 7200},
            {label: '3 Hours', value: 10800},
            {label: '6 Hours', value: 21600},
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib5: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: false,
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib6: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: false,
        timeranges: [
            {label: '1 Hour', value: 3600},
            {label: '2 Hours', value: 7200},
            {label: '3 Hours', value: 10800},
            {label: '6 Hours', value: 21600},
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        social_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib7: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: false,
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    articles: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: false,
        periods: [
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        colors: {
            'GOOGLE_TRENDS': [56, 161, 105],
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46]
        }
    },
    sources: [
        // {label: 'Trendsmap', value: 'TRENDSMAP'},
        {label: 'Trends24', value: 'TRENDS24'},
        {label: 'Google Trends', value: 'GOOGLE_TRENDS'},
        {label: 'X Trends', value: 'TWITTER_TRENDS'},
        {label: 'Telegram', value: 'TELEGRAM'},
        {label: 'Commoninja.com', value: 'COMMONINJA'},
        {label: 'Observer', value: 'NITTER'},
        {label: 'RSS', value: 'RSS'}
    ],
    rss_platforms: [
        {label: 'Instagram', value: 'RSS_INSTAGRAM'},
        {label: 'Facebook', value: 'RSS_FACEBOOK'},
        {label: 'X', value: 'RSS_TWITTER'},
        {label: 'Telegram', value: 'RSS_TELEGRAM'},
        {label: 'TikTok', value: 'RSS_TIKTOK'},
        {label: 'YouTube', value: 'RSS_YOUTUBE'},
        {label: 'Web', value: 'RSS_OTHER'},
        {label: 'Vk', value: 'RSS_VK'},
        {label: 'Ok', value: 'RSS_OK'}
    ],
    trends_platforms: [
        {label: 'Instagram', value: 'INSTAGRAM'},
        {label: 'Facebook', value: 'FACEBOOK'},
        {label: 'X', value: 'TWITTER'},
        {label: 'Telegram', value: 'TELEGRAM'},
        {label: 'TikTok', value: 'TIKTOK'},
        {label: 'YouTube', value: 'YOUTUBE'},
        {label: 'Web', value: 'OTHER'},
        {label: 'Vk', value: 'VK'},
        {label: 'Ok', value: 'OK'}
    ],
    credential_platforms: [
        {label: 'X', value: 'TWITTER', base_url: 'https://x.com'}
    ]
}
export default configs;