import React, {useEffect, useState} from 'react';
import {IntlProvider} from 'react-intl';
import Farsi from '../../lang/fa.json';
import Arabic from '../../lang/ar.json';
import EnglishUS from '../../lang/en-US.json';
import TrFarsi from '../../lang/tr-fa.json';
import TrArabic from '../../lang/tr-ar.json';
import TrEnglishUS from '../../lang/tr-en-US.json';
import AcFarsi from '../../lang/ac-fa.json';
import AcArabic from '../../lang/ac-ar.json';
import AcEnglishUS from '../../lang/ac-en-US.json';
import {configs} from "../../Configs";

export const LocaleContext = React.createContext();
const langMapping = {
    ac: {'en-US': AcEnglishUS, 'fa': AcFarsi, 'ar': AcArabic},
    tr: {'en-US': TrEnglishUS, 'fa': TrFarsi, 'ar': TrArabic},
    af: {'en-US': EnglishUS, 'fa': Farsi, 'ar': Arabic},
    az: {'en-US': EnglishUS, 'fa': Farsi, 'ar': Arabic},
    bh: {'en-US': EnglishUS, 'fa': Farsi, 'ar': Arabic},
    ea: {'en-US': EnglishUS, 'fa': Farsi, 'ar': Arabic},
    iq: {'en-US': EnglishUS, 'fa': Farsi, 'ar': Arabic},
    lb: {'en-US': EnglishUS, 'fa': Farsi, 'ar': Arabic},
    pa: {'en-US': EnglishUS, 'fa': Farsi, 'ar': Arabic},
    ru: {'en-US': EnglishUS, 'fa': Farsi, 'ar': Arabic},
    sy: {'en-US': EnglishUS, 'fa': Farsi, 'ar': Arabic}
}
const langs = {
    'en-US': {messages: langMapping[configs.country_code]['en-US'], isRTL: false},
    'fa': {messages: langMapping[configs.country_code]['fa'], isRTL: true},
    'ar': {messages: langMapping[configs.country_code]['ar'], isRTL: true}
}

const LocaleProvider = (props) => {
    // set locale to en-US as a default language
    const [locale, setLocale] = useState('en-US');
    const [messages, setMessages] = useState();
    const [isRTL, setIsRTL] = useState(false);

    useEffect(() => {
        const storedLocale = localStorage.getItem('locale');
        const defLocale = Object.keys(langs).includes(navigator.language) ? navigator.language : Object.keys(langs)[0];
        setLocale(storedLocale || defLocale);
    }, []);

    useEffect(() => {
        setMessages(langs[locale]?.messages);
        setIsRTL(langs[locale]?.isRTL);
        localStorage.setItem('locale', locale);
    }, [locale]);

    function changeLanguage() {
        const locales = Object.keys(langs);
        let localeIndex = locales.indexOf(locale);
        localeIndex = (localeIndex + 1) % locales.length;
        setLocale(locales[localeIndex]);
    }

    return (
        <LocaleContext.Provider value={{locale, isRTL, changeLanguage}}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </LocaleContext.Provider>
    );
}

export default LocaleProvider;