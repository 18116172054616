import {Box, Container, Spinner, Text} from "@chakra-ui/react";
import {useCallback, useEffect, useRef, useState} from "react";
import {AddUpdateCredentialModal} from "./AddUpdateCredentialModal";
import {SearchCredentialModal} from "./SearchCredentialModal";
import {CredentialCard} from "./CredentialCard";
import {useAuth} from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxois";
import axios from "axios";
import {FormattedMessage} from "react-intl";
// import {MostActiveCredentialBarChart} from "../Charts/MostActiveCredentialBarChart";

export const CredentialList = () => {
    const axiosInstance = useAxios();
    const {hasPermission} = useAuth();
    const [credentials, setCredentials] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(false);
    const [filters, setFilters] = useState(null);
    const [selectedCredentials, setSelectedCredentials] = useState([]);

    const [error, setError] = useState(false);
    const [lastElmId, setLastElmId] = useState(null);
    const [pageId, setPageId] = useState(null);
    const [nextPageId, setNextPageId] = useState(null);
    const observer = useRef();

    useEffect(() => {
        onSubmit(true);
    }, [pageId]);

    useEffect(() => {
        if (filters === null) return;
        onSubmit();
    }, [filters]);

    const onSubmit = (paginate = false) => {
        setLastElmId(null);
        setLoading(true);
        setError(false);
        let cancel;

        // handle pagination
        let payload = {
            cancelToken: new axios.CancelToken(c => cancel = c),
            params: paginate ? {...filters, after: pageId} : filters
        }

        if (!paginate) {
            setNextPageId(null)
            setPageId(null)
            setCredentials([])
        }

        axiosInstance.get("/credential/", payload).then((res) => setCredentials(prev => {
            // discard previous results if it's not paginating
            const theData = paginate ? [...prev, ...res.data] : [...res.data];

            // uniqify data combination
            let itteratedKeys = [];
            let newData = [];
            theData.map(credentialItem => {
                if (!itteratedKeys.includes(credentialItem.credential_id)) {
                    itteratedKeys.push(credentialItem.credential_id);
                    newData.push(credentialItem);
                }
            });

            // the nextPageId is determined by least sort (desc)
            setLastElmId(theData.length ? theData[theData.length - 1].credential_id : null);
            setNextPageId(theData.length ? theData[theData.length - 1].credential_id : null)
            setLoading(false);
            setError(false);

            return newData;
        })).catch((error) => {
            if (axios.isCancel(error)) return;
            setLoading(false);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
        return () => cancel();
    }

    const lastCredentialElmRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && nextPageId) {
                setPageId(nextPageId)
            }
        })
        if (node) observer.current.observe(node);
    }, [loading, nextPageId]);

    return (
        <Container mt={9}>
            {/*<MostActiveCredentialBarChart/>*/}
            {hasPermission('credential', 'fe_create') && <AddUpdateCredentialModal mb={1} onSuccess={onSubmit}/>}
            {hasPermission('resource', 'fe_manage') &&
                <SearchCredentialModal mt={1} setFilters={setFilters} filters={filters}/>}
            <Box mt={6}>
                {credentials?.map((credential) => {
                    return <CredentialCard
                        credential={credential}
                        key={credential.credential_id}
                        refr={credential.credential_id === lastElmId ? lastCredentialElmRef : undefined}
                    />
                })}
                <Box w='100%' textAlign='center'>
                    {!credentials.length && !loading &&
                        <Text>
                            <FormattedMessage id="responses.noData"/>
                        </Text>}
                    {!loading && error &&
                        <Text>
                            <FormattedMessage id="responses.connectionFailed"/>
                        </Text>}
                    {loading && <Spinner mt={5} color="green"/>}
                </Box>
            </Box>
        </Container>
    );
};
